import { dev } from '$app/environment';
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/sveltekit';

export async function initSentry() {
  Sentry.init({
    dsn: 'https://df1a32ff871ba9c6a799586a64a2b2bd@o4506769124622336.ingest.us.sentry.io/4507074593619968',
    tracesSampleRate: 1.0,

    environment: import.meta.env.MODE,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [
      replayIntegration(),
      captureConsoleIntegration({
        levels: ['error']
      })
    ]
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
